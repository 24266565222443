import { Component, OnInit, HostListener } from '@angular/core';
import { animate, trigger, state, style, transition } from '@angular/animations';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.styl'],
  animations: [
    trigger('scroll', [
      state('show', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('* => out, * => in', animate('0.5s')),
    ]),
  ],

})
export class ScrollTopButtonComponent implements OnInit {

  public displayButton: string; // スクロール判定フラグ

  // HostListnerがスクロール位置を検知してボタンを表示・非表示
  @HostListener('window:scroll', []) onWindowScroll() {

    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 10) {
      this.displayButton = 'show';
    } else {
      this.displayButton = 'hidden';
    }
  }

  constructor() { }

  ngOnInit() {
    this.displayButton = 'hidden';
  }

  // ボタンが押された場合, ページ上部へスクロールする
  public scrollToTop(): void {
    document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}
