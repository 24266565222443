import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private ERROR: any = {};

  private errorMessageMst: any = {
    // [/list]
    '01-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(01-01)' },
    '01-02': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(01-02)' },
    '02-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(02-01)' },
    '02-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(02-03)' },
    '02-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(02-04)' },
    '02-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(02-05)' },
    '02-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(02-06)' },
    // [/detail]
    '03-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(03-01)' },
    '03-02': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(03-02)' },
    '04-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(04-01)' },
    '04-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(04-03)' },
    '04-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(04-04)' },
    '04-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(04-05)' },
    '04-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(04-06)' },
    '04-07': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。（04-07）' },
    '04-08': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。（04-08）' },
    '04-09': { title: 'エラー',  message: 'エラーが発生しました。再度、アクセスください。（04-09）' },
    // [/lottery] (先くじ)
    '05-01': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(05-01)' },
    '05-02': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(05-02)' },
    '06-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(06-01)' },
    '06-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(06-03)' },
    '06-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(06-04)' },
    '06-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(06-05)' },
    '06-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(06-06)' },
    '06-07': { title: 'エラー',  message: 'ご指定のくじは終了しました。ほかのくじをお楽しみください。(06-07)' },
    '06-09': { title: 'エラー',  message: 'すでにくじを引いていますほかのくじをお楽しみください。(06-09)' },
    '06-12': { title: 'エラー',  message: 'くじを引くにはウエルシアメンバーの登録が必要です。こちらからウエルシアメンバーの登録を行ってください。（06-12）https://web.tsite.jp/serv/welcia/member/' },
    // [/lottery] (後くじ)
    '09-01': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(09-01)' },
    '09-02': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(09-02)' },
    '10-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(10-01)' },
    '10-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(10-03)' },
    '10-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(10-04)' },
    '10-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(10-05)' },
    '10-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(10-06)' },
    '10-07': { title: 'エラー',  message: 'ご指定のくじは終了しました。ほかのくじをお楽しみください。(10-07)' },
    '10-09': { title: 'エラー',  message: 'すでにくじを引いていますほかのくじをお楽しみください。(10-09)' },
    '10-12': { title: 'エラー',  message: 'くじを引くにはウエルシアメンバーの登録が必要です。こちらからウエルシアメンバーの登録を行ってください。（10-12）https://web.tsite.jp/serv/welcia/member/' },
    // [/entry] (後くじ)
    '07-01': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(07-01)' },
    '07-02': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(07-02)' },
    '08-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(08-01)' },
    '08-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(08-03)' },
    '08-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(08-04)' },
    '08-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(08-05)' },
    '08-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(08-06)' },
    '08-07': { title: 'エラー',  message: 'ご指定のくじは終了しました。ほかのくじをお楽しみください。(08-07)' },
    '08-09': { title: 'エラー',  message: 'エントリー期間外です。ほかのくじをお楽しみください。(08-09)' },
    '08-12': { title: 'エラー',  message: 'くじを引くにはウエルシアメンバーの登録が必要です。こちらからウエルシアメンバーの登録を行ってください。（08-12）https://web.tsite.jp/serv/welcia/member/' },
    // [/history] (くじ詳細ページ内)
    '11-01': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(11-01)' },
    '11-02': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(11-02)' },
    '12-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(12-01)' },
    '12-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(12-03)' },
    '12-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(12-04)' },
    '12-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(12-05)' },
    '12-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(12-06)' },
    // [/history] (くじ履歴ページ)
    '13-01': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(13-01)' },
    '13-02': { title: 'エラー',  message: '通信に問題がありました。ページを更新してください。(13-02)' },
    '14-01': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(14-01)' },
    '14-03': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(14-03)' },
    '14-04': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(14-04)' },
    '14-05': { title: 'システムメンテナンスのお知らせ',  message: 'システムのメンテナンス作業のため、ご利用いただくことができません。(14-05)' },
    '14-06': { title: 'エラー',  message: 'ただいまアクセスできません。しばらく経ってから再度お試しください。(14-06)' },
  } as const;

  constructor() { }

  getErrorMessage(code: string): any {
    return this.errorMessageMst[code];
  }
}
