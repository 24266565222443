import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentFactoryService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  public resolveComponent(component: any, viewContainerRef: ViewContainerRef): ComponentRef<any> {
    // 動的コンポーネント情報を解決する
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    return viewContainerRef.createComponent(componentFactory);
  }
}
