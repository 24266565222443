import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { CommonFooterComponent } from './components/common-footer/common-footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DateParsePipe } from './pipes/date-parse.pipe';
import { HtmlSanitizePipe } from './pipes/html-sanitize.pipe';
import { UrlSanitizePipe } from './pipes/url-sanitize.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { ServiceMenuModalComponent } from './components/service-menu-modal/service-menu-modal.component';
import { ScrollTopButtonComponent } from './components/scroll-top-button/scroll-top-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { CommonOverlayComponent } from './components/common-overlay/common-overlay.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { CommonNoticeComponent } from './components/common-notice/common-notice.component';

import { THeaderModule } from './components/t-header/t-header.module';

@NgModule({
  declarations: [
    DateParsePipe,
    HtmlSanitizePipe,
    UrlSanitizePipe,
    Nl2brPipe,
    CommonHeaderComponent,
    CommonFooterComponent,
    LoadingComponent,
    ServiceMenuModalComponent,
    ScrollTopButtonComponent,
    CommonModalComponent,
    CommonOverlayComponent,
    ErrorPageComponent,
    CommonNoticeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    THeaderModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateParsePipe,
    HtmlSanitizePipe,
    UrlSanitizePipe,
    Nl2brPipe,
    CommonHeaderComponent,
    CommonFooterComponent,
    LoadingComponent,
    ScrollTopButtonComponent,
    CommonModalComponent,
    CommonOverlayComponent,
    ErrorPageComponent,
    CommonNoticeComponent,
  ],
})
export class SharedModule { }
