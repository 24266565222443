import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { UrlPramResolverService } from './core/services/url-pram-resolver.service';

const routes: Routes = [
  {
    path: '',
    resolve: { route: UrlPramResolverService },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'top',
        loadChildren: () => import('./list/list.module').then(m => m.ListModule),
        canActivate: [RedirectGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'top2',
        loadChildren: () => import('./list/list.module').then(m => m.ListModule),
      },
      {
        path: 'detail/:kujiid',
        loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule),
      },
      {
        path: 'detail2/:kujiid',
        loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule),
      },
      {
        path: 'history',
        loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
      },
      {
        path: 'history2',
        loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
      },
      {
        path: '**',
        redirectTo: 'top',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    useHash: true,
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
