import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '@shared/shared.module';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { ComponentFactoryService } from './services/component-factory.service';
import { ErrorService } from './services/error.service';
import { LoginService } from './services/login.service';
import { ModalService } from './services/modal.service';
import { UrlPramResolverService } from './services/url-pram-resolver.service';
import { StoreService } from './services/store.service';
import { ConfettiService } from './services/confetti.service';
import { SeoService } from './services/seo.service';
import { GtmService } from './services/gtm.service';
import { NoticeService } from './services/notice.service';
import { CookieService } from './services/cookie.service';
@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    UrlPramResolverService,
    ComponentFactoryService,
    LoginService,
    ModalService,
    ErrorService,
    StoreService,
    ConfettiService,
    SeoService,
    GtmService,
    NoticeService,
    CookieService,
  ],
  imports: [
    SharedModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
