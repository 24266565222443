import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  private fileName = `notice.json`;

  constructor(private http: HttpClient) { }

  getNotice(): any {
    return this.http.get(`${environment.cdnPath}/notice/${this.fileName}`);
  }
}
