// 本番環境用変数
export const environment = {
  production: true,
  apiUrl: 'https://lot.tsite.jp/api',
  cdnPath: 'https://img.tsite.jp/resources/lot',
  gtmCategory: 'lot',
  garaponBannerJsonPath:
    'https://img.tsite.jp/resources/tsite/lot/json/garapon_bnr.json',
  bannarPath: 'https://img.tsite.jp/resources/tsite/lot/img/',
  domainPath: 'https://lot.tsite.jp',
  maintenanceJson: 'https://img.tsite.jp/system/shirase/maintenance.json',
  commonResourcesPath: 'https://img.tsite.jp/resources/common',
};
