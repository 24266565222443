<div class="wrap-modal">
  <div class="modal" id="modal" [class]="modal.class" [@openClose]="isOpen ? 'open' :'closed' ">
    <div class="modal-container">
      <div class="modal-top">
        <span class="close-button" (click)="closeModal()"></span>
      </div>
      <div class="modal-content">
        <p class="modal-title">{{ modal.title }}</p>
        <p class="modal-message">{{ modal.message }}</p>
      </div>
      <button type="button" class="modal-close" id="modal-close" aria-label="モーダルを閉じる"
      (click)="closeModal()">閉じる</button>
    </div>
  </div>

</div>

<app-common-overlay (click)="closeModal()" [@openClose]="isOpen ? 'open' :'closed'"></app-common-overlay>
