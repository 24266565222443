import { AfterViewChecked, Component, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';
import { ModalService } from '@core/services/modal.service';
import { NoticeService } from '@core/services/notice.service';
import { SeoService } from '@core/services/seo.service';
import { StoreService } from '@core/services/store.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  @ViewChild('modal', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private storeService: StoreService,
    private seoService: SeoService,
    private modalService: ModalService,
    private noticeService: NoticeService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    // 汎用モーダルを準備
    this.modalService.createViewContainer = this.viewContainerRef;

    // ルーティング開始を検知
    this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((params: any) => {
      // ルーティングからtitle, meta情報を取得
      const titleMeta = this.seoService.getTitleMeta(params.url.split('/')[1]);

      // title, meta情報をセット
      this.title.setTitle(titleMeta.title);
      this.meta.updateTag({ name: 'desctiption', content: titleMeta.desctiption });
    });
  }

  ngAfterViewChecked() {
    this.resizeHeight();
  }

  resizeHeight() {
    const main = document.querySelector('main');
    const article = document.querySelector('article');

    const headerHeight = (document.querySelector('header')) ? document.querySelector('header').offsetHeight : 0;
    const footerHeight = (document.querySelector('footer')) ? document.querySelector('footer').offsetHeight : 0;
    const contentsHeight = document.documentElement.clientHeight - (footerHeight + headerHeight);

    if (main) {
      this.renderer.setStyle(main, 'min-height', `${ contentsHeight }px`);

      if (article) {
        this.renderer.setStyle(article, 'min-height', `${ contentsHeight }px`);
      }
    }
  }
}
