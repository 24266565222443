import { Injectable } from '@angular/core';
import * as cookie from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor() { }

  public get(name: string): string {
    return cookie.get(name);
  }

  public set(name: string, value: string | object, options?: cookie.CookieAttributes): string {
    return cookie.set(name, value, (options) ? options : {});
  }

  public remove(name: string): void {
    return cookie.remove(name);
  }
}
