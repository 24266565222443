import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { THeaderComponent } from './t-header/t-header.component';

@NgModule({
  declarations: [THeaderComponent],
  imports: [CommonModule, RouterModule],
  exports: [THeaderComponent],
})
export class THeaderModule {}
