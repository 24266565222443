import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // GET[page]が存在する場合のみ実行
    if (next.queryParams.page) {

      // GETパラメータオブジェクトの作成
      const params = {};
      Object.keys(next.queryParams).forEach((key) => {
        // ルーティングに使用するパラメータ [page, id] 以外を追加
        if (key !== 'page' && key !== 'id') { params[key] = next.queryParams[key]; }
      });

      // リダイレクト先
      let route = next.queryParams.page;

      // GET[id]が存在する場合、子ルーティングを追加する
      if (next.queryParams.id) {
        route += `/${next.queryParams.id}`;
      }

      this.router.navigate([route], { queryParams: params });
    }

    return true;
  }
}
