import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class UrlPramResolverService implements Resolve<any> {

  constructor(private storeService: StoreService) { }

  // ルーティング前にGETパラメータを取得してstoreServiceにセットする
  resolve(route: ActivatedRouteSnapshot) {

    // ルーティングをセット
    // [/#/{ルーティング}] を [/] で分割、2番目の文字列を取得
    this.storeService.set('route',  window.location.hash.split('/')[1]);

    // getパラメータをセット
    this.storeService.set('urlParams', route.queryParams);

    // getパラメータをセット
    this.storeService.set('urlFragment', route.fragment);
    return;
  }
}
