import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request;
    // 開発環境のみCORSを有効にする
    if (!environment.production) {
      newRequest.clone({ withCredentials: true });
      console.log('[development] withCredentials: true');
    }
    return next.handle(request);
  }
}
