import { Injectable, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { CommonModalComponent } from '@shared/components/common-modal/common-modal.component';
import { Subject } from 'rxjs';
import { ComponentFactoryService } from './component-factory.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // モーダルウィンドウの作成元
  private viewContainerRef: ViewContainerRef;
  // モーダルコンポーネント情報
  private currentComponent = null;

  constructor(private componentFactoryService: ComponentFactoryService) { }

  set createViewContainer(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  /**
   * モーダルを表示
   * @param viewContainerRef モーダルウィンドウの作成元
   * @param param エラー表示用オブジェクト, title, messageを渡す
   */
  public openCommonModal(param: any) {
    // 既に表示されているモーダルを削除してから、新しくモーダルを作成する
    this.currentComponent && this.currentComponent.destroy();

    const componentRef = this.componentFactoryService.resolveComponent(CommonModalComponent, this.viewContainerRef);

    // 作成したモーダルウィンドウにデータを渡す。
    componentRef.instance.modal = param;
    // モーダル削除用関数 close() を渡す
    componentRef.instance.modal.close = () => this.currentComponent && this.currentComponent.destroy();

    this.currentComponent = componentRef;
  }
}
