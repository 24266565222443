import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  visited = false;

  /**
   * GTMの読み込みをチェック
   */
  private useGTM(): boolean {
    return typeof dataLayer !== undefined;
  }

  constructor() { }

  sendEvent(action: string, label: string, event?: string): void {
    if (!this.useGTM()) {
      return;
    }
    dataLayer.push({
      category: environment.gtmCategory,
      action,
      label,
      event: event || 'clickEventTrigger',
    });
  }

  sendEventSetCategory(category:string,action: string, label: string, event?: string): void {
    if (!this.useGTM()) {
      return;
    }
    dataLayer.push({
      category,
      action,
      label,
      event: event || 'clickEventTrigger',
    });
  }

  sendPv(): void {
    if (!this.useGTM()) {
      return;
    }
    dataLayer.push({
      category: environment.gtmCategory,
      event: 'spa_pageview',
    });
  }
}
