import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

/**
 * {URL}/{ルーティング}{パラメータ} を
 * {URL}/#/{ルーティング}{パラメータ} の形に修正
 *
 */
const url = window.location;
if (url.hash.split('/')[0] !== '#') {
  url.href = `${url.origin}/#/${url.search}`;
}
