import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.styl'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        display: 'none',
        opacity: 0,
      })),
    ]),
  ],
})
export class CommonModalComponent implements OnInit {
  @Input() modal: any;
  isOpen: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isOpen = true;
  }

  /**
  * モーダルウィンドウを非表示にする。
  * ウィンドウの破棄は次にモーダルウィンドウのを呼び出したときに、
  * モーダルサービスで行うため、ここでは非表示にするだけ。
  */
  closeModal() {
    this.modal.close();
    this.isOpen = false;
  }
}
