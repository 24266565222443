import { Injectable } from '@angular/core';
import * as confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {

  constructor() { }

  confetti(cvs): void {
    const myConfetti = confetti.create(cvs, {
      resize: true,
      useWorker: true,
    });

    let skew = 1;

    const frame = () => {
      skew = Math.max(0.8, skew - 0.001);

      myConfetti({
        particleCount: 1,
        startVelocity: 0,
        gravity: 0.5,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: (Math.random() * skew) - 0.2,
        },
        colors: [this.setColor()],
        scalar: 1,
      });

      window.requestAnimationFrame(frame);

    };
    frame();
  }

  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  setColor(): string {
    const c = Array(3);
    c[0] = Math.floor(255);
    c[1] = Math.floor(Math.random() * 256);
    c[2] = Math.floor(Math.random() * (256 - c[1] / 2));
    c[0] = (`0${c[0].toString(16)}`).slice(-2);
    c[1] = (`0${c[1].toString(16)}`).slice(-2);
    c[2] = (`0${c[2].toString(16)}`).slice(-2);
    c.sort((): number => {
      const n = [1, 0 , -1];
      return (n[Math.floor(Math.random() * 3)]);
    });
    return (`#${c[0]}${c[1]}${c[2]}`);
  }
}
