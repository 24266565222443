import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParamService } from './http-param.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(
    private httpParamService: HttpParamService,
    private router: Router,
  ) { }

  sendLogin(loginUrl: string): void {
    const page = this.router.url.split('?')[0].split('/')[1];
    const id = this.router.url.split('?')[0].split('/')[2];
    const param = this.router.url.split('?')[1];

    let dynamicUrl = `page=${page}`;
    if (id) { dynamicUrl += `&id=${id}`; }
    if (param) { dynamicUrl += `&${param}`; }

    window.location.href = `${loginUrl}&DYNAMIC_URL=${this.httpParamService.encodeValue(dynamicUrl)}`;
    return;
  }
}
