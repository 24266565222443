import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SeoService {

  public titleMetaMst: any = {
    top: {
      title: 'Vくじ一覧',
      desctiption: 'VくじでVポイントを当てよう！毎日、無料で引けるくじがいっぱいあります。今すぐ、くじを引こう！',
    },
    top2: {
      title: 'Vくじ一覧',
      desctiption: 'VくじでVポイントを当てよう！毎日、無料で引けるくじがいっぱいあります。今すぐ、くじを引こう！',
    },
    detail: {
      title: '',
      desctiption: 'VくじでVポイントを当てよう！毎日、無料で引けるくじがいっぱいあります。今すぐ、くじを引こう！',
    },
    detail2: {
      title: '',
      desctiption: 'VくじでVポイントを当てよう！毎日、無料で引けるくじがいっぱいあります。今すぐ、くじを引こう！',
    },
    history: {
      title: 'Vくじ当選履歴',
      desctiption: '',
    },
    history2: {
      title: 'Vくじ当選履歴',
      desctiption: '',
    },
  } as const;

  getTitleMeta(page: string) {
    return this.titleMetaMst[page];
  }

  constructor() { }
}
